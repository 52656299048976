div.enrollRow {
  display: grid;
  grid-template-columns: repeat(3, 1fr) 3fr;
}

@media (max-width: 767px) {
  div.enrollRow {
    grid-gap: 5px;
    grid-template-columns: 1fr;
  }
}
