div.adminMenu {
  display: grid;
  grid-template-columns: 140px repeat(3, 180px);
}

div.adminPost {
  display: grid;
  grid-template-columns: 1fr 80px 90px;
  grid-gap: 10px;
  max-width: 700px;
}
